<script setup lang="ts">

import {MandantStatus} from "@generated/de/lohn24/model/mandant/MandantStatus"
import IconWartend from "@lib/view/icons/IconWartend.vue"
import IconWeiter from "@lib/view/icons/IconWeiter.vue"
import {Id} from "@lib/common/model/Id"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {FontSize} from "@lib/common/Enums"
import {ElMessageBox} from "element-plus"
import {Config} from "@lib/Config"
import {Resource} from "@lib/model/Resource"
import {Account} from "@generated/dev/api/model/account/Account"
import {DateTime} from "luxon"

const props = withDefaults(
    defineProps<{
        mandant: Id<Mandant>
        kennung: string
        status: MandantStatus,
        von: Id<Account> | null,
        am: DateTime | null,
        notiz: string | null
        btnClass?: string | object
        size?: FontSize
    }>(),
    {
        size: FontSize.small,
        btnClass: "",
    },
)

const emits = defineEmits<{
    "update:status": [status: MandantStatus]
    "update:notiz": [notiz: string | null]
    "update:von": [von: Id<Account> | null]
    "update:am": [am: DateTime | null]
    "cancel": [e: "cancel"]
    "mandant": [mandant: Mandant]
}>()

function emitResult(result: Mandant) {
    emits("update:notiz", result.statusNotiz)
    emits("update:status", result.status)
    emits("update:von", result.statusNotizVon)
    emits("update:am", result.statusNotizAm)
    emits("mandant", result)
}

const messageBoxOptions = {
    confirmButtonText: Config.buttonLabel.CONFIRM_BUTTON_TEXT,
    cancelButtonText: Config.buttonLabel.CANCEL_BUTTON_TEXT,
    confirmButtonClass: Config.buttonLabel.CONFIRM_BUTTON_CLASS,
    cancelButtonClass: Config.buttonLabel.CANCEL_BUTTON_CLASS,
}

async function confirmWarten() {
    try {
        const {value} = await ElMessageBox.prompt(
            "Grund für die Statusänderung:",
            "Status auf 'wartend' setzen",
            messageBoxOptions,
        )
        const result = await Resource.mandant.warten(props.mandant, value)
        emitResult(result)
    } catch (e) {
        if (e === "cancel") {
            emits("cancel", e)
        } else {
            throw e
        }
    }
}

async function confirmAktivieren() {
    try {
        await ElMessageBox.confirm(
            `Begründung für bisherigen Status: ${props.notiz}`,
            "Status auf 'abrechenbar' setzen",
            messageBoxOptions,
        )
        const result = await Resource.mandant.weiter(props.mandant)
        emitResult(result)
    } catch (e) {
        if (e === "cancel") {
            emits("cancel", e)
        } else {
            throw e
        }
    }
}


</script>

<template>
  <el-tooltip :show-after="1000" placement="top">
    <template #content>
      <span v-if="props.status === MandantStatus.WARTEN">
        Mandant {{ props.kennung }} auf abrechenbar setzen
      </span>
      <span v-else>
        Mandant {{ props.kennung }} auf wartend setzen
      </span>
    </template>
    <el-button
            v-if="props.status === MandantStatus.WARTEN"
            :class="btnClass"
            text
            v-bind="$attrs"
            @click.stop="confirmAktivieren()"
    >
      <icon-weiter :size="size" />
    </el-button>
    <el-button v-else :class="btnClass" text v-bind="$attrs" @click.stop="confirmWarten()">
      <icon-wartend :size="size" />
    </el-button>
  </el-tooltip>
</template>

<style>

</style>