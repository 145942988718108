<script setup lang="ts">

import IconGesperrt from "@lib/view/icons/IconGesperrt.vue"
import IconEntsperrt from "@lib/view/icons/IconEntsperrt.vue"
import {Id} from "@lib/common/model/Id"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {Account} from "@generated/dev/api/model/account/Account"
import {DateTime} from "luxon"
import {FontSize} from "@lib/common/Enums"
import {ElMessageBox} from "element-plus"
import {Config} from "@lib/Config"
import {Resource} from "@lib/model/Resource"

const props = withDefaults(
    defineProps<{
        mandant: Id<Mandant>
        kennung: string
        gesperrt: boolean,
        von: Id<Account> | null,
        am: DateTime | null,
        notiz: string | null
        btnClass?: string | object
        size?: FontSize
    }>(),
    {
        size: FontSize.small,
        btnClass: "",
    },
)

const emits = defineEmits<{
    "update:gesperrt": [gesperrt: boolean]
    "update:notiz": [notiz: string | null]
    "update:von": [von: Id<Account> | null]
    "update:am": [am: DateTime | null]
    "cancel": [e: "cancel"]
    "mandant": [mandant: Mandant]
}>()

const messageOption = {
    confirmButtonText: Config.buttonLabel.CONFIRM_BUTTON_TEXT,
    cancelButtonText: Config.buttonLabel.CANCEL_BUTTON_TEXT,
    confirmButtonClass: Config.buttonLabel.CONFIRM_BUTTON_CLASS,
    cancelButtonClass: Config.buttonLabel.CANCEL_BUTTON_CLASS,
}

function emitResult(result: Mandant) {
    emits("update:notiz", result.gesperrtNotiz)
    emits("update:gesperrt", result.gesperrt)
    emits("update:von", result.gesperrtNotizVon)
    emits("update:am", result.gesperrtNotizAm)
    emits("mandant", result)
}

async function confirmUnlock(): Promise<void> {
    try {
        await ElMessageBox.confirm(
            `Begründung für bisherigen Status: ${props.notiz}`,
            "Mandant entsperren?",
            messageOption,
        )
        const result = await Resource.mandant.entsperren(props.mandant)
        emitResult(result)
    } catch (e: any) {
        if (e === "cancel") {
            emits("cancel", e)
        } else {
            throw e
        }
    }
}

async function confirmLock() {
    try {
        const {value} = await ElMessageBox.prompt(
            "Grund für die Statusänderung:",
            "Status auf 'gesperrt' setzen",
            messageOption,
        )

        const result = await Resource.mandant.sperren(props.mandant, value)
        emitResult(result)
    } catch (e) {
        if (e === "cancel") {
            emits("cancel", e)
        } else {
            throw e
        }
    }
}

</script>

<template>
  <el-tooltip :show-after="1000" placement="top">
    <template #content>
      <span v-if="props.gesperrt">Den Mandanten {{ props.kennung }} entsperren</span>
      <span v-else>Mandant {{ props.kennung }} sperren</span>
    </template>
    <el-button
            v-if="props.gesperrt"
            :class="btnClass"
            text
            v-bind="$attrs"
            @click.stop="confirmUnlock()"
    >
      <icon-entsperrt :size="size" />
    </el-button>
    <el-button v-else :class="btnClass" text v-bind="$attrs" @click.stop="confirmLock()">
      <icon-gesperrt :size="size" />
    </el-button>
  </el-tooltip>
</template>