<script setup lang="ts">

import {FontSize} from "@lib/common/Enums"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {ElMessage, ElMessageBox} from "element-plus"
import {Config} from "@lib/Config"
import {Resource} from "@lib/model/Resource"
import {ValidationError} from "@lib/common/axios/AxiosError"
import {Id} from "@lib/common/model/Id"

const props = withDefaults(
    defineProps<{
        mandant: Id<Mandant>,
        kennung: string,
        btnClass?: string,
        size?: FontSize
    }>(),
    {
        btnClass: "",
        size: FontSize.small,
    },
)

const emits = defineEmits<{
    "mandant": [mandant: Mandant]
    "cancel": [e: "cancel"]
}>()

async function confirmReaktiviereInaktivenMandanten(): Promise<void> {
    try {
        await ElMessageBox.confirm("Status auf Aktiv setzen?", "Status auf 'aktiv' setzen", {
            confirmButtonText: Config.buttonLabel.CONFIRM_BUTTON_TEXT,
            cancelButtonText: Config.buttonLabel.CANCEL_BUTTON_TEXT,
            confirmButtonClass: Config.buttonLabel.CONFIRM_BUTTON_CLASS,
            cancelButtonClass: Config.buttonLabel.CANCEL_BUTTON_CLASS,
        })
        const result = await Resource.mandant.aktiviereInaktivenMandanten(props.mandant)
        emits("mandant", result)
    } catch (e: any) {
        if (e === "cancel") {
            emits("cancel", e)
        } else if (e instanceof ValidationError) {
            e.response!.data.forEach(data => {
                ElMessage({
                    message: `Validierung Fehlgeschlagen: ${data.message}`,
                    type: "error",
                })
            })
        } else {
            throw e
        }
    }
}

</script>

<template>
  <el-tooltip :show-after="1000" placement="top">
    <template #content>
      <span>Den Mandanten {{ props.kennung }} reaktivieren</span>
    </template>
    <el-button
            :class="btnClass"
            text
            v-bind="$attrs"
            @click.stop="confirmReaktiviereInaktivenMandanten()"
    >
      REAKTIVIEREN
    </el-button>
  </el-tooltip>
</template>

<style scoped lang="scss">

</style>