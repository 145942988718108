<script setup lang="ts">
import {ref, shallowRef} from "vue"
import IconPostcard from "@lib/view/icons/IconPostcard.vue"
import PostanschriftEtikett from "@intranet/view/etiketthtml/PostanschriftEtikett.vue"
import {Firma} from "@generated/de/lohn24/model/firma/Firma"
import {Postanschrift} from "@generated/de/lohn24/model/brief/Postanschrift"
import {Anschrift} from "@generated/de/lohn24/model/anschrift/Anschrift"
import {Id} from "@lib/common/model/Id"
import {Resource} from "@lib/model/Resource"

const props = defineProps<{
    anschrift: Id<Anschrift> | Anschrift
    rechnungssteller: Id<Firma>
}>()

const showDialog = ref<boolean>(false)

const absender = shallowRef<Firma | null>(null)
const postanschrift = shallowRef<Postanschrift | null>(null)

async function toggleDialog(){

    showDialog.value = !showDialog.value
    if(props.anschrift instanceof Anschrift)
        postanschrift.value = props.anschrift.postanschrift
    postanschrift.value = postanschrift.value ?? (await Resource.anschrift.find(props.anschrift))?.postanschrift
    absender.value = absender.value ?? await Resource.firma.find(props.rechnungssteller)
}

</script>

<template>
  <el-tooltip
          :show-after="1000"
          content="Anschriftenetikett"
          placement="top"
  >
    <el-button text @click="toggleDialog()">
      <icon-postcard />
    </el-button>
  </el-tooltip>
  <postanschrift-etikett
          v-if="absender && postanschrift"
          v-model:visible="showDialog"
          :absender="absender!"
          :postanschrift="postanschrift!"
  />
</template>