import {MenuItem} from "@lib/common/menu/MenuItem"
import {MitarbeiterMenuItem} from "@intranet/view/mitarbeiter/MitarbeiterMenuItem"
import {routeTo} from "@intranet/plugin/router/Router"
import {Config} from "@lib/Config"
import {captureException} from "@sentry/vue"

export const ErrorMenuItem = new MenuItem({
    label: "Error",
    name: "error",
    view: () => import("@intranet/view/error/Error.vue"),
    visible: false,
    props: {error: null, from: null},
    hooks: {
        async beforeEnter(to, from, next) {
            //@ts-expect-error not declared
            if (ErrorMenuItem.props.error == null)
                return next({name: MitarbeiterMenuItem.routeRecordName})

            //@ts-expect-error not declared
            ErrorMenuItem.props.from = from
            return next()
        },
    },
})
Config.showDataPullFailedMessage = async function showDataPullFailedMessage(e: unknown, message?: string) {
    if (e instanceof Error)
        await showError(e)
}
Config.showDataSaveFailedMessage = async function showDataSaveFailedMessage(e: unknown, message?: string) {
    if (e instanceof Error)
        await showError(e)
}

export async function showError(e: Error) {
    //@ts-expect-error not declared
    ErrorMenuItem.props.error = e
    captureException(e)
    return routeTo(ErrorMenuItem)
}