<script lang="ts" setup>

import {ChainedCommands, EditorContent, JSONContent, useEditor} from "@tiptap/vue-3"
import {StarterKit} from "@tiptap/starter-kit"
import {ref} from "vue"
import {Commands, CommandState} from "@lib/common/richtexteditor/DataType"
import {Image} from "@tiptap/extension-image"
import {TextStyle} from "@tiptap/extension-text-style"
import {FontSize} from "tiptap-extension-font-size"

const emits = defineEmits<{
    "update:modelValue": [content: string]
    "change:text": [text: string]
    "change:json": [content: JSONContent]
}>()

const props = defineProps<{
    modelValue: string,
}>()

const editor = useEditor({
    content: props.modelValue,
    extensions: [
        StarterKit,
        TextStyle.configure({
            mergeNestedSpanStyles: false,
        }),
        Image.configure({
            inline: true,
            allowBase64: true,
        }),
        FontSize,
    ],
    onUpdate: ({editor}) => {
        emits("update:modelValue", editor.getHTML())
        emits("change:text", editor.getText())
        emits("change:json", editor.getJSON())
        updateCommandStates()
    },
    onSelectionUpdate: () => {
        updateCommandStates()
    },
})

const commandState = ref<CommandState>({
    undo: false,
    redo: false,
    bold: false,
    italic: false,
    paragraph: false,
    heading1: false,
    heading2: false,
    heading3: false,
    heading4: false,
    heading5: false,
    image: false,
})

const commands: Commands = {
    undo: () => runCommand((it) => it.undo()),
    redo: () => runCommand(it => it.redo()),
    bold: () => runCommand(it => it.toggleBold()),
    italic: () => runCommand(it => it.toggleItalic()),
    paragraph: () => runCommand(it => it.setParagraph()),
    heading1: () => runCommand(it => it.setHeading({level: 1})),
    heading2: () => runCommand(it => it.setHeading({level: 2})),
    heading3: () => runCommand(it => it.setHeading({level: 3})),
    heading4: () => runCommand(it => it.setHeading({level: 4})),
    heading5: () => runCommand(it => it.setHeading({level: 5})),
    fontsize: (size?: number) => {
        runCommand(it => {
            console.log(size)
            if (size)
                return it.setMark("textStyle", {fontSize: size + "pt"})
            else
                return it.unsetMark("textStyle")
        })
    },
    image: (src: string, title: string, alt: string) => runCommand(it => it.setImage({src, alt, title})),
}

function updateCommandState(name: string, attributes?: NonNullable<unknown>, append: string = "") {
    commandState.value[name + append] = editor.value?.isActive(name, attributes)
}

function updateCommandStates() {
    [
        ["undo"],
        ["redo"],
        ["bold"],
        ["italic"],
        ["paragraph"],
        ["heading", {level: 1}, "1"],
        ["heading", {level: 2}, "2"],
        ["heading", {level: 3}, "3"],
        ["heading", {level: 4}, "4"],
        ["heading", {level: 5}, "5"],
    ].forEach(([name, attributes, append]) => updateCommandState(name as string, attributes, append as string))
}

function runCommand(command: (chain: ChainedCommands) => ChainedCommands) {
    command(editor.value!.chain().focus()).run()
    updateCommandStates()
}


</script>

<template>
  <div class="rich-text-editor">
    <slot :commands="commands" :state="commandState" name="toolbar"></slot>
    <slot v-if="editor" :editor="editor" name="editor">
      <editor-content
              class="editor-content"
              v-if="editor"
              :editor="editor"
      />
    </slot>
    <slot :commands="commands" :state="commandState" name="toolbar-bottom"></slot>
  </div>
</template>

<style lang="scss">
.rich-text-editor {

  width: 100%;

  .ProseMirror {
    margin: 1px;
    padding: 1px 5px;
    // - padding.left, padding.right, margin.left, margin.right
    width: calc(100% - 12px);

    //--ck-focus-ring: solid 1px var(--el-input-focus-border-color, var(--el-color-primary));
    box-shadow: 0 0 0 1px var(--el-input-border-color, var(--el-border-color)) inset;
    border-radius: var(--el-input-border-radius, var(--el-border-radius-base));

    &.ProseMirror-focused {
      outline-color: var(--el-input-focus-border-color, var(--el-color-primary));
      outline-style: solid;
      outline-width: 1px;
    }
  }
}
</style>