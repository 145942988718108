<template>
  <summary-dialog
          v-if="anschrift"
          :anschrift="anschrift"
          :controller="controller"
          :edit="edit || false"
          @edit="controller.edit($event)"
  >
    <template #header-right>
      <pmc-button :mandant="viewModel.id" />
      <mandant-zammad-suche
              :kennung="viewModel.kennung"
      />
      <el-button disabled style="cursor: default" text>
        <el-divider direction="vertical"></el-divider>
      </el-button>
      <mandant-otrs-suche
              :kennung="viewModel.kennung"
      />
      <mandant-status-button
              :mandant="viewModel.mandant"
              btnClass="dialog-headline-btn"
              @update:mandant="controller.mergeModel($event)"
      />
      <button-mandant-abschluss
              v-if="abschliessen && controller.viewModel.isActive && !controller.viewModel.gesperrt"
              :mandant="viewModel.mandant"
              :tooltip-disabled="!controller.visible.value"
              btnClass="dialog-headline-btn"
      />
    </template>
    <template #anschrift-button-group>
      <mandant-anschriftentraegr-button :id="controller.mandant.id" />
      <postanschrift-etikett-button
              v-if="debitor"
              :anschrift="anschrift"
              :rechnungssteller="debitor.rechnungssteller"
      />
    </template>
    <template #content-left-append>
      <postanschrift-etikett
              v-model:visible="showEtikett"
              v-if="firma"
              :absender="firma"
              :postanschrift="anschrift.postanschrift"
      />
      <kontakt-summary
              v-if="kontakte && !controller.viewModel.keinDirektkontakt"
              :data="kontakte"
      >
        <template #email-prefix="{kontakt}">
          <mandant-zammad-ticket
                  :kontakt="kontakt"
                  :mandant="viewModel.mandant.id"
          >
          </mandant-zammad-ticket>
        </template>
      </kontakt-summary>
      <mandant-kooperationspartner
              v-if="viewModel.kooperationspartner !== null"
              :mandant="viewModel.mandant"
              :model-value="viewModel.kooperationspartner"
      >
        <template #header-right="{anschrift}">
          <mandant-anschriftentraegr-button
                  :id="controller.mandant.id"
                  kooperationspartner
          />
          <postanschrift-etikett-button
                  v-if="debitor"
                  :anschrift="anschrift"
                  :rechnungssteller="debitor.rechnungssteller"
          />
        </template>
      </mandant-kooperationspartner>

    </template>
    <template #content-right>
      <abschlussdaten
              :debitor="debitor"
              :mandant="viewModel.mandant"
              class="m-b-30"
      />
      <mandant-organisation
              v-if="organisationMandanten?.length"
              :mandanten="organisationMandanten"
      />
    </template>
    <template #default>
      <el-row :gutter="40">
        <el-col v-if="viewModel.hatHinweise" :span="24">
          <h3>Hinweise</h3>
          <el-collapse accordion>
            <el-collapse-item v-if="viewModel.bearbeitungshinweis.isNotEmpty()" title="Bearbeitung">
              <bearbeitungshinweis :modelValue="viewModel.bearbeitungshinweis" />
            </el-collapse-item>
            <el-collapse-item v-if="viewModel.abschlusshinweis.isNotEmpty()" title="Abschluss">
              <bearbeitungshinweis :modelValue="viewModel.abschlusshinweis" />
            </el-collapse-item>
            <el-collapse-item v-if="viewModel.versandhinweis.isNotEmpty()" title="Versand">
              <bearbeitungshinweis :modelValue="viewModel.versandhinweis" />
            </el-collapse-item>
          </el-collapse>
        </el-col>
      </el-row>
    </template>
  </summary-dialog>
</template>

<script lang="ts">
import {computed, defineComponent, PropType, ref, shallowRef} from "vue"
import SummaryDialog from "@intranet/view/SummaryDialog.vue"
import {auth} from "@lib/common/Authentication"
import MandantAbschlussButton from "@intranet/view/mandant/MandantAbschlussButton.vue"
import Bearbeitungshinweis from "@intranet/view/mandant/component/Bearbeitungshinweis.vue"
import MandantAbschlussdaten from "@intranet/view/mandant/abschlussdaten/MandantAbschlussdaten.vue"
import {ComponentRenderer} from "@lib/common/dialog/ComponentRenderer"
import KontaktSummary from "@intranet/view/kontakt/KontaktSummary.vue"
import MandantKooperationspartner from "@intranet/view/mandant/kooperationspartner/MandantKooperationspartner.vue"
import MandantAnschriftentraegrButton
    from "@intranet/view/mandant/anschriftentraeger/MandantAnschriftentraegrButton.vue"
import {MandantSummaryDialogController} from "@intranet/view/mandant/summary/MandantSummaryDialogController"
import {Anschrift} from "@generated/de/lohn24/model/anschrift/Anschrift"
import {Kontakt} from "@generated/de/lohn24/model/kontakt/Kontakt"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import MandantOtrsSuche from "@intranet/view/mandant/component/MandantOTRSSuche.vue"
import {MandantSummaryViewModel} from "@intranet/view/mandant/summary/MandantSummaryViewModel"
import {Debitor} from "@generated/de/lohn24/model/debitor/Debitor"
import MandantAnschriftenetikettButton
    from "@intranet/view/mandant/anschriftentraeger/MandantAnschriftenetikettButton.vue"
import MandantOrganisation from "@intranet/view/mandant/organisation/MandantOrganisation.vue"
import MandantZammadSuche from "@intranet/view/mandant/ticket/MandantZammadSuche.vue"
import MandantZammadTicket from "@intranet/view/mandant/ticket/MandantZammadTicket.vue"
import {SetUp} from "@element-plus/icons-vue"
import PmcButton from "@intranet/view/mandant/pmc/PmcButton.vue"
import PostanschriftEtikett from "@intranet/view/etiketthtml/PostanschriftEtikett.vue"
import {Firma} from "@generated/de/lohn24/model/firma/Firma"
import PostanschriftEtikettButton from "@intranet/view/etiketthtml/PostanschriftEtikettButton.vue"
import MandantStatusButton from "@intranet/view/mandant/status/MandantStatusButton.vue"

export default defineComponent({
    components: {
        MandantStatusButton,
        PostanschriftEtikettButton,
        PostanschriftEtikett,
        PmcButton,
        SetUp,
        MandantZammadTicket,
        MandantZammadSuche,
        MandantOrganisation,
        MandantAnschriftenetikettButton,
        MandantOtrsSuche,
        MandantAnschriftentraegrButton,
        MandantKooperationspartner,
        KontaktSummary,
        SummaryDialog,
        ButtonMandantAbschluss: MandantAbschlussButton,
        Bearbeitungshinweis,
        Abschlussdaten: MandantAbschlussdaten,
    },
    props: {
        edit: {
            type: Boolean,
        },
        abschliessen: {
            type: Boolean,
        },
        controller: {
            type: Object as PropType<MandantSummaryDialogController>,
            required: true,
        },
        ...ComponentRenderer.ComponentRendererProps<Mandant>(),
    },
    emits: ["update:controller"],
    setup(props) {
        const anschrift = shallowRef<Anschrift | null>(null)
        const debitor = shallowRef<Debitor | null>(null)
        const firma = shallowRef<Firma | null>(null)
        const kontakte = shallowRef<Kontakt[] | null>(null)
        const organisationMandanten = shallowRef<Mandant[] | null>(null)

        const viewModel = computed<MandantSummaryViewModel>(() => props.controller.viewModel)
        const showEtikett = ref<boolean>(false)

        props.controller.anschrift(anschrift)
        props.controller.kontakte(kontakte)
        props.controller.debitor(debitor, firma)
        props.controller.organisationMandanten(organisationMandanten)

        return {
            auth,
            debitor,
            firma,
            anschrift,
            kontakte,
            viewModel,
            showEtikett,
            organisationMandanten,
        }
    },
})
</script>

<style lang="scss">

.el-collapse-item__header.is-active {
  font-weight: bold;
}

</style>
