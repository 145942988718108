<script setup lang="ts">
import {ref} from "vue"
import RichTextEditor from "@lib/common/richtexteditor/RichTextEditor.vue"
import {EditorContent, JSONContent} from "@tiptap/vue-3"
import {uuidv4} from "@lib/common/uuid/UUID"

const props = withDefaults(
    defineProps<{
        width?: string
        height?: string
        content?: string
    }>(),
    {
        width: "61mm",
        height: "40mm",
    },
)
const paddingTop = "4mm"
const paddingLeft = "3mm"
const paddingBottom = "0"
const paddingRight = "0"
const lineHeight = "10pt"

const emits = defineEmits<{
    "update:content": [content: string]
}>()

const printable = ref<typeof HTMLDivElement>()
const printableId = uuidv4()
const paragraphs = ref<JSONContent[] | undefined>([])
const htmlText = ref<string>(props.content ?? "")

function fontFamilies(): string[] {
    const result: string[] = []
    for (const sheet of document.styleSheets) {
        for (const rule of sheet.cssRules) {
            if (rule instanceof CSSFontFaceRule)
                result.push(rule.cssText)
        }
    }
    return result
}

function print() {
    const printFrame = document.createElement("iframe")!
    printFrame.srcdoc = `
    <html>
      <head>
        <style>
          ${fontFamilies().join("\n")}
          *{
            font-family: Roboto !important;
            line-height: ${lineHeight};
          }
          @page {
            page-orientation: upright;
            size: ${props.width} ${props.height}  ;
            margin: 0;
          }
          body {
            padding: ${paddingTop} ${paddingRight} ${paddingBottom} ${paddingLeft};
            margin: 0;
            p:first-of-type {
              margin-block: 0;
            }
          }
        </style>
      </head>
      <body>
        ${htmlText.value}
      </body>
    </html>
    `
    document.body.appendChild(printFrame)
    setTimeout(function () {
        printFrame.contentWindow!.print()
        document.body.removeChild(printFrame)
    }, 1000)
}

</script>

<template>
  <el-row :gutter="20" class="etikett">
    <el-col class="etikett-container">
      <rich-text-editor
              :model-value="htmlText"
              @update:modelValue="emits('update:content', $event);htmlText=$event"
              @change:json="paragraphs=$event.content"
      >
        <template #toolbar="{commands, state}">
          <el-row justify="end" align="middle" class="toolbar-container">
            <div>
              <el-button-group size="default">
                <el-button icon="fas-print" text @click="print()"></el-button>
              </el-button-group>
            </div>
          </el-row>
        </template>
        <template #editor="{editor}">
          <el-row style="" justify="center" align="middle" class="editor-container">
            <editor-content :editor="editor" class="editor-content" />
          </el-row>
        </template>
      </rich-text-editor>
    </el-col>
    <div v-if="htmlText" class="preview" style="display: none">
      <div
              v-html="htmlText"
              class="printable"
              ref="printable"
              :id="printableId"
              :style="`height: ${props.height}; width: ${props.width}; padding: ${paddingTop} ${paddingRight} ${paddingBottom} ${paddingLeft};`"
      />
    </div>
  </el-row>
</template>

<style lang="scss">
.etikett {
  .printable {
    p:first-of-type {
      margin-block: 0;
    }
  }

  .etikett-container {
    //width: 134mm;
    //height: 80mm;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .toolbar-container {
    width: 633px;
  }

  .editor-container {
    width: 800px;
    height: 282px;

    .editor-content {
      scale: 2;
      line-height: 10pt;

      div {
        padding-right: 0;
        padding-bottom: 0;
        padding-left: 3mm;
        padding-top: 3mm;
        width: 57mm;
        height: 34mm;

        p:first-of-type {
          margin-block: 0;
        }
      }

    }
  }

  //.editor-content {
  //  scale: 2;
  //}
}

</style>