<script setup lang="ts">

import {MandantStatus} from "@generated/de/lohn24/model/mandant/MandantStatus"
import IconInaktiv from "@lib/view/icons/IconInaktiv.vue"
import {Id} from "@lib/common/model/Id"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {FontSize} from "@lib/common/Enums"
import {ElMessageBox} from "element-plus"
import {Config} from "@lib/Config"
import {Resource} from "@lib/model/Resource"

const props = withDefaults(
    defineProps<{
        mandant: Id<Mandant>
        kennung: string
        status: MandantStatus,
        btnClass?: string | object
        size?: FontSize
    }>(),
    {
        size: FontSize.small,
        btnClass: "",
    },
)

const emits = defineEmits<{
    "update:status": [status: MandantStatus]
    "cancel": [e: "cancel"]
    "mandant": [mandant: Mandant]
}>()

const messageOptions = {
    confirmButtonText: Config.buttonLabel.CONFIRM_BUTTON_TEXT,
    cancelButtonText: Config.buttonLabel.CANCEL_BUTTON_TEXT,
    confirmButtonClass: Config.buttonLabel.CONFIRM_BUTTON_CLASS,
    cancelButtonClass: Config.buttonLabel.CANCEL_BUTTON_CLASS,
}

function emitResult(result: Mandant){
    emits("update:status", result.status)
    emits("mandat", result)
}

async function confirmInaktiv(): Promise<void> {
    try {
        await ElMessageBox.confirm("Status auf Inaktiv setzen?", "Status auf 'inaktiv' setzen", messageOptions)
        const result = await Resource.mandant.setzeInaktiv(props.mandant)
        emitResult(result)
    } catch (e: any) {
        if (e === "cancel") {
            emits("cancel", e)
        } else {
            throw e
        }
    }
}
</script>

<template>
  <el-tooltip :show-after="1000" placement="top">
    <template #content>
      <span>Den Mandanten {{ props.kennung }} inaktiv setzen</span>
    </template>
    <el-button
            :class="btnClass"
            text
            v-bind="$attrs"
            @click.stop="confirmInaktiv()"
    >
      <icon-inaktiv :size="size" />
    </el-button>
  </el-tooltip>
</template>

<style scoped lang="scss">

</style>