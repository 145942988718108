<script setup lang="ts">
import {FontSize} from "@lib/common/Enums"
import {auth} from "@lib/common/Authentication"
import {RightAccess} from "@lib/model/role/RoleRight"
import {RightIdentifier} from "@generated/de/lohn24/model/konstanten/RightIdentifier"
import MandantStatusReaktivierenButton from "@intranet/view/mandant/status/MandantStatusReaktivierenButton.vue"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {MandantStatus} from "@generated/de/lohn24/model/mandant/MandantStatus"
import MandantStatusInaktivButton from "@intranet/view/mandant/status/MandantStatusInaktivButton.vue"
import MandantSperrenButton from "@intranet/view/mandant/status/MandantSperrenButton.vue"
import MandantStatusWartenButton from "@intranet/view/mandant/status/MandantStatusWartenButton.vue"

const props = withDefaults(
    defineProps<{
        mandant: Mandant
        btnClass?: string | object
        size?: FontSize
    }>(),
    {
        btnClass: "",
        size: FontSize.small,
    },
)

const emits = defineEmits<{
    "update:mandant": [mandant: Mandant]
}>()

const hasAccess = auth.hasAccess([RightAccess.editable(RightIdentifier.RECHNUNGSWESEN)])

</script>

<template>
  <mandant-status-reaktivieren-button
          v-if="hasAccess && props.mandant.status == MandantStatus.INAKTIV"
          :mandant="props.mandant.id"
          :kennung="props.mandant.kennung"
          :btn-class="props.btnClass"
          :size="props.size"
          @mandant="emits('update:mandant', $event)"
  />
  <mandant-status-inaktiv-button
          v-if="hasAccess && mandant.kuendigungsdatum && mandant.status !== MandantStatus.INAKTIV"
          :mandant="props.mandant.id"
          :kennung="props.mandant.kennung"
          :status="props.mandant.status"
          :btn-class="props.btnClass"
          :size="props.size"
          @mandant="emits('update:mandant', $event)"
  />
  <mandant-sperren-button
          v-if="hasAccess"
          :mandant="props.mandant.id"
          :kennung="props.mandant.kennung"
          :gesperrt="props.mandant.gesperrt"
          :von="props.mandant.gesperrtNotizVon"
          :am="props.mandant.gesperrtNotizAm"
          :notiz="props.mandant.gesperrtNotiz"
          :btn-class="props.btnClass"
          :size="props.size"
          @mandant="emits('update:mandant', $event)"
  />
  <mandant-status-warten-button
          v-if="!mandant.gesperrt && mandant.status !== MandantStatus.INAKTIV"
          :mandant="props.mandant.id"
          :kennung="props.mandant.kennung"
          :status="props.mandant.status"
          :von="props.mandant.statusNotizVon"
          :am="props.mandant.statusNotizAm"
          :notiz="props.mandant.statusNotiz"
          :btn-class="props.btnClass"
          :size="props.size"
          @mandant="emits('update:mandant', $event)"
  />
</template>

<style scoped lang="scss">

</style>