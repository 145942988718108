export enum DataType {
    HTML,
    JSON,
    Y
}

export enum Heading {
    PARAGRAPH,
    HEADING1,
    HEADING2,
    HEADING3,
}

export interface Commands {
    undo(): void

    redo(): void

    bold(): void

    italic(): void

    paragraph(): void

    heading1(): void

    heading2(): void

    heading3(): void

    heading4(): void

    heading5(): void

    fontsize(size?: number): void

    image(src: string, title: string, alt: string): void
}

export interface CommandState {
    undo: boolean
    redo: boolean
    bold: boolean
    italic: boolean
    paragraph: boolean
    heading1: boolean
    heading2: boolean
    heading3: boolean
    heading4: boolean
    heading5: boolean
    image: boolean
}