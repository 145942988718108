import {Plugin} from "vue"
import {Router} from "vue-router"
import * as Sentry from "@sentry/vue"
import {Config} from "@lib/Config"

export const SentryPlugin: Plugin = {
    async install(app, options: { router: Router, environment: string }): Promise<void> {
        if (Config.environment == "production")
            Sentry.init({
                app: app,
                release: __GITHUB_SHA__,
                dsn: "https://7593f1a10151aaa5e3b5c0a95e1e5e2a@sentry.lohn24.de/10",
                integrations: [
                    Sentry.browserTracingIntegration({router: options.router}),
                    Sentry.browserProfilingIntegration(),
                    Sentry.replayIntegration({
                        maskAllText: true,
                        blockAllMedia: true,
                    }),
                    Sentry.dedupeIntegration(),
                ],
                environment: options.environment,
                debug: false,
                replaysSessionSampleRate: 0.1,
                replaysOnErrorSampleRate: 1.0,
            })
    },
}