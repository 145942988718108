import {computed, Ref, ref} from "vue"
import Api from "@lib/common/axios/Api"
import {Account} from "@generated/dev/api/model/account/Account"
import {ElMessage} from "element-plus"
import {Config} from "@lib/Config"
import {ModelWorker} from "@lib/common/worker/ModelWorker"
import {Resource} from "@lib/model/Resource"
import {from, unwrapped} from "@lib/common/Functions"
import {MitarbeiterArbeitsplatz} from "@generated/de/lohn24/model/mitarbeiter/MitarbeiterArbeitsplatz"
import {Mitarbeiter} from "@generated/de/lohn24/model/mitarbeiter/Mitarbeiter"
import {MitarbeiterStatus} from "@generated/de/lohn24/model/mitarbeiter/MitarbeiterStatus"
import {LocalStorage} from "@lib/common/LocalStorage"
import {RightAccess} from "@lib/model/role/RoleRight"
import {Session} from "@generated/dev/api/model/session/Session"
import {UnauthorizedError} from "@lib/common/axios/AxiosError"

const TOKEN = "TOKEN"

export function aOrNotB(has: boolean, needs: boolean): boolean {
    return has || !needs
}

export default class Authentication {
    private static _instance: Authentication
    statedInitialize: boolean = false
    private resource = Resource
    private initialized = false
    private api = Api.Instance(Config.API_BASE_URL)
    private mitarbeiter_: Ref<Mitarbeiter> = ref(from(Mitarbeiter, {})) as Ref<Mitarbeiter>
    mitarbeiter = computed(() => {
        return this.mitarbeiter_.value
    })

    public static get Instance(): Authentication {
        return this._instance || (this._instance = new this())
    }

    get me() {
        return this.mitarbeiter_.value
    }

    private _session = ref<Session | null>(Authentication.defaultSession())

    get session(): Session | null {
        if (this._session)
            return unwrapped(this._session) as Session

        return null
    }

    static onUnauthorized(instance: Authentication): void {
        Authentication.removeToken()
        Authentication.clearSession(instance)
        instance.initialized = false
    }

    static clearSession(instance: Authentication) {
        instance._session.value = Authentication.defaultSession()
    }

    static onForbidden(instance: Authentication): void {
        Authentication.removeToken()
        Authentication.clearSession(instance)
        instance.initialized = false
    }

    private static defaultSession() {
        return null
        // return from(Session, {
        //     token: "",
        //     account: 0,
        //     groups: [],
        //     roles: [],
        //     rights: [],
        // })
    }

    private static getToken(): string | null {
        return window.localStorage.getItem(TOKEN)
    }

    private static setToken(token: string) {
        LocalStorage.Instance.set(TOKEN, token)
        this._instance.api.setBearerToken(token)
    }

    private static removeToken() {
        window.localStorage.removeItem(TOKEN)
        this._instance.api.removeBearerToken()
    }

    private static sessionHasRight(session: Session, access: RightAccess): boolean {
        const rights = session.rights.filter(
            (sessionRechte) =>
                sessionRechte.right.name === access.right &&
                aOrNotB(sessionRechte.delete, access.delete) &&
                aOrNotB(sessionRechte.read, access.read) &&
                aOrNotB(sessionRechte.write, access.write),
        )
        return rights.length > 0
    }

    private static hasAccess(session: Session, accesses: Array<RightAccess>): boolean {
        const length = accesses.filter((access) => !this.sessionHasRight(session, access)).length
        return length === 0
    }

    hasNoSession() {
        return this.session === null
    }

    hasAccess(accesses: Array<RightAccess>): boolean {
        return this.session !== null && Authentication.hasAccess(this.session, accesses)
    }

    async login(username: string, password: string, arbeitsplatz: MitarbeiterArbeitsplatz = MitarbeiterArbeitsplatz.OFFICE): Promise<void> {
        const session = await this.resource.session.create(username, password)
        if (session === null) return
        Authentication.setToken(session.token)
        this._session.value = session
        this.mitarbeiter_.value = await Resource.mitarbeiter.me()
        this.mitarbeiter_.value.arbeitsplatz = arbeitsplatz
        this.mitarbeiter_.value.mitarbeiterStatus = MitarbeiterStatus.ONLINE
        await this.updateMitarbeiter(unwrapped(this.mitarbeiter_))
    }

    async updateMitarbeiter(mitarbeiter: Mitarbeiter): Promise<void> {
        this.mitarbeiter_.value.merge(await Resource.mitarbeiter.updateStatus(mitarbeiter))
    }

    async logout(): Promise<void> {
        try {
            await this.resource.session.close()
            // Object.assign(this._session, Session.GuestSession)
            ModelWorker.Instance.delete(Session)
            LocalStorage.Instance.clear()
        } catch (e) {
            if (Config.development)
                ElMessage.error("Fehlgeschlagen")
        }
        Authentication.removeToken()
        ModelWorker.Instance.delete(Account)
        this.mitarbeiter_.value = from(Mitarbeiter, {})
        this.initialized = false
    }

    async initialise(): Promise<boolean> {
        const token = Authentication.getToken()
        try {
            if (!this.initialized && !this.statedInitialize && token) {
                this.statedInitialize = true
                Authentication.setToken(token)
                const session = await ModelWorker.Instance.get(Session)
                this._session.value = session ?? await this.getSession() ?? Authentication.defaultSession()
                const mitarbeiter = await ModelWorker.Instance.get(Mitarbeiter)
                this.mitarbeiter_.value = mitarbeiter ?? await Resource.mitarbeiter.me()
                this.initialized = true
                this.statedInitialize = false
                return true
            }
            return false
        } catch (e) {
            if(e instanceof UnauthorizedError)
                return false
            else
                throw e
        }
    }

    valid(): boolean {
        if (this.session === null)
            return false

        return this.session.account !== null && this.session.token !== ""
    }

    clearSession() {
        Authentication.clearSession(this)
    }

    private async getSession() {
        try {
            return await this.resource.session.get()
        } catch (e) {
            Authentication.removeToken()
            Authentication.clearSession(this)
            this.initialized = false
            window.location.reload()
        }
    }
}

export const auth = Authentication.Instance

