<script lang="ts" setup>
import {Commands, CommandState, Heading} from "@lib/common/richtexteditor/DataType"
import {FontSize} from "@lib/common/Enums"

const props = withDefaults(
    defineProps<{
        commands: Commands,
        state: CommandState,
        size: FontSize
        undo?: boolean
    }>(),
    {
        undo: true,
    },
)

function activeHeading(state: {
    paragraph: boolean,
    heading1: boolean,
    heading2: boolean,
    heading3: boolean
}): Heading {
    if (state.heading1) return Heading.HEADING1
    if (state.heading2) return Heading.HEADING2
    if (state.heading3) return Heading.HEADING3
    return Heading.PARAGRAPH
}

</script>

<template>
  <el-row class="p-b-5">
    <el-button-group class="rich-text-toolbar">
      <slot name="prepend"></slot>
      <el-button
              icon="fas-undo"
              text
              @click="commands.undo()"
              v-if="undo"
      />
      <el-button
              icon="fas-redo"
              text
              @click="commands.redo()"
              v-if="undo"
      />
      <el-button class="divider-button" disabled text v-if="undo">
        <el-divider direction="vertical"></el-divider>
      </el-button>

      <el-select
              :model-value="activeHeading(state)"
              :size="size"
              class="el-button is-text"
      >
        <el-option :value="Heading.PARAGRAPH" label="Text" @click="commands.paragraph()"></el-option>
        <el-option :value="Heading.HEADING1" label="Überschrift 1" @click="commands.heading1()"></el-option>
        <el-option :value="Heading.HEADING2" label="Überschrift 2" @click="commands.heading2()"></el-option>
        <el-option :value="Heading.HEADING3" label="Überschrift 3" @click="commands.heading3()"></el-option>
      </el-select>
      <el-button class="divider-button" disabled text>
        <el-divider direction="vertical"></el-divider>
      </el-button>
      <el-button
              :class="{'is-on': state.bold}"
              icon="fas-bold"
              text
              @click="commands.bold()"
      />
      <el-button
              :class="{'is-on': state.italic}"
              icon="fas-italic"
              text
              @click="commands.italic()"
      />
      <el-button class="divider-button" disabled text>
        <el-divider direction="vertical"></el-divider>
      </el-button>
      <el-select :size="size" class="el-button is-text">
        <el-option v-for="index in 15" :key="index" :value="index + 5" :label="`${index}px`"  @click="commands.fontsize(index)"/>
      </el-select>
      <slot name="append"></slot>
    </el-button-group>
  </el-row>
</template>

<style lang="scss">
.rich-text-toolbar {
  .divider-button {
    cursor: default !important;
  }

  .el-select {
    width: 150px;
    display: flex;
    padding-left: 0;
    padding-right: 0;
  }

  .is-on {
    background-color: var(--el-button-active-bg-color);
  }
}

</style>