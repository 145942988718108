import {ListController} from "@lib/common/controller/ListController"
import {NewsUngelesenViewModel} from "@intranet/view/news/ungelesen/NewsUngelesenViewModel"
import {ListItemIsNotUpdateableException} from "@lib/common/Error"
import {Resource} from "@lib/model/Resource"
import {auth} from "@lib/common/Authentication"
import {Config} from "@lib/Config"
import {NewsEditDialog} from "@intranet/view/news/edit/NewsEditDialog"
import {assertNotNull} from "@lib/common/Functions"

export class NewsUngelesenListController extends ListController<NewsUngelesenViewModel> {

    override get data(): NewsUngelesenViewModel[] {
        return super.data
            .filter(it => it.isReleased())
            .sortBy(it => assertNotNull(it.updated).toSeconds() * -1)
    }

    create(): Promise<void> {
        return Promise.resolve(undefined)
    }

    override async editRow(row: NewsUngelesenViewModel): Promise<void> {
        await NewsEditDialog.show(row.news.id)
    }

    async setzeGelesen(news: NewsUngelesenViewModel) {
        try {
            await Resource.news.setzteGelesen(news.news.id)
            await this.fetch()
        } catch (e) {
            return Config.showDataSaveFailedMessage(e)
        }
    }

    async pollNews() {
        return await this.fetch()
    }

    subscribe() {
        this.useRouter()
    }

    unsubscribe() {
    }

    protected async fetchData(args: any): Promise<NewsUngelesenViewModel[]> {
        if (auth.valid()) {
            const result = await Resource.news.ungeleseneNews()
            return result.map(it => new NewsUngelesenViewModel(it))
        }

        return []
    }

    protected updateData(data: NewsUngelesenViewModel[], args: any) {
        throw new ListItemIsNotUpdateableException()
    }

}

export const newsUngelesenListController = new NewsUngelesenListController()