<script setup lang="ts">

import {Postanschrift} from "@generated/de/lohn24/model/brief/Postanschrift"
import {Firma} from "@generated/de/lohn24/model/firma/Firma"
import {ref} from "vue"
import EtikettHtml from "@intranet/view/etiketthtml/EtikettHtml.vue"

const props = defineProps<{
    absender: Firma
    postanschrift: Postanschrift
    visible: boolean
}>()

const emits = defineEmits<{
    "update:visible": [visible: boolean]
}>()

const content = ref<HTMLDivElement>()
</script>

<template>
  <el-dialog
          :model-value="props.visible"
          @update:modelValue="emits('update:visible', $event)"
          title="Postanschrift Etikett"
  >
    <el-row class="postanschrift-etikett" justify="center">
      <div ref="content" style="display: none">
        <p class="test" >
          <span style="font-size: 4.8pt">{{ props.absender.ruecksendeangabe }}</span><br>
          <span style="font-size: 10pt">{{ props.postanschrift.zeile5 }}</span><br>
          <span style="font-size: 10pt">{{ props.postanschrift.zeile6 }}</span><br>
          <span style="font-size: 10pt">{{ props.postanschrift.zeile7 }}</span><br>
          <span style="font-size: 10pt">{{ props.postanschrift.zeile8 }}</span><br>
          <span style="font-size: 10pt" v-if="props.postanschrift.zeile9">{{
              props.postanschrift.zeile9
            }}</span><br v-if="props.postanschrift.zeile9">
          <span style="font-size: 10px" v-if="props.postanschrift.zeile10">{{ props.postanschrift.zeile10 }}</span><br
                v-if="props.postanschrift.zeile10"
        >
          <span style="font-size: 10px" v-if="props.postanschrift.zeile11">{{ props.postanschrift.zeile11 }}</span><br
                v-if="props.postanschrift.zeile11"
        >
        </p>
      </div>
      <div class="etikett-container">
        <etikett-html
                v-if="content?.innerHTML"
                :content="content?.innerHTML"
        />
      </div>
    </el-row>
  </el-dialog>
</template>